import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Meta from 'components/meta'
import Section from 'components/section'
import Wrapper from 'components/wrapper'

const GlobalComponents = {
  Col,
  Section,
  Row,
}

interface Props {
  children: React.ReactNode
  location: Location
  pageContext: unknown
}

const MdxDefault = ({ children, location, pageContext }: Props) => {
  const { title, author, description } = pageContext.frontmatter
  return (
    <Wrapper location={location} className="w-100 mx-auto d-flex flex-column bg-body-dark">
      <Meta title={title} author={author} description={description} />
      <Row className="py-5 justify-content-center text-center bg-body-dark">
        <Col xs={12} sm={10} md={9} lg={8}>
          <MDXProvider components={GlobalComponents}>{children}</MDXProvider>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default MdxDefault
